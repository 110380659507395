import { Controller } from '@hotwired/stimulus'
import Turbo from '~js/turbo'

export default class extends Controller {
  static targets = ['fileButton', 'submit']
  static values = { duplicate: Object, observeable: Array } // { source: target }

  debouncedSubmit() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.submit()
    }, 300)
  }

  connect() {
    this.observer = new MutationObserver(this.observe.bind(this))

    if (this.observeableValue.length > 0) {
      this.observeableValue.forEach((selector) => {
        this.observer.observe(this.element.querySelector(selector), {
          childList: true
        })
      })
    }
  }

  disconnect() {
    this.observer.disconnect()
  }

  observe(mutationList, _observer) {
    mutationList.forEach((mutation) => {
      if (mutation.type === 'childList') {
        const { target } = mutation;

        const hasChildren = target.childNodes.length > 0;
        const hasText = target.textContent.trim().length > 0;

        if (hasChildren || hasText) {
          this.activeSubmit()
        } else {
          this.inactiveSubmit()
        }
      }
    })
  }

  activeSubmit() {
    this.submitTarget.disabled = false
  }

  inactiveSubmit() {
    this.submitTarget.disabled = true
  }

  fileInput(event) {
    const input = this.fileButtonTarget.parentElement.querySelector('input[type="file"]')

    if (input.files.length == 0) {
      event.preventDefault()
      input.click()
    } else {
      this.fileButtonTarget.click()
    }
  }

  submit() {
    if (this.hasDuplicateValue) {
      for (const [source, target] of Object.entries(this.duplicateValue)) {
        const sourceElement = this.element.querySelector(source)
        const targetElement = this.element.querySelector(target)
        targetElement.value = sourceElement.textContent
      }
    }

    this.element.requestSubmit()
  }

  next(event) {
    if (event.detail.success) {
      const fetchResponse = event.detail.fetchResponse

      if (fetchResponse.response.url && !fetchResponse.response.headers.get('X-SKIP-NEXT-FORM-URL')) {
        history.pushState(
          { turbo_frame_history: true },
          '',
          fetchResponse.response.url
        )

        Turbo.visit(fetchResponse.response.url)
      }
    }
  }

  resetFormPartAndSendUpdate(event) {
    const section = this.element.querySelector(event.params.removeSectionClass)
    if (section) section.remove()
    this.element.insertAdjacentHTML('afterbegin', '<input type="hidden" name="onlyUpdateView" value="1">')
    this.submit()
  }
}
