import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    body: { type: Boolean, default: false },
  }

  connect() {
    this.scrollToBottom()

    this.observer = new MutationObserver(() => {
      this.scrollToBottom()
    })

    this.observer.observe(this.element, { subtree: true, childList: true })

    this.visibilityObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.scrollToBottom()  // Scroll when iframe becomes visible
          this.visibilityObserver.disconnect()
        }
      })
    })

    this.visibilityObserver.observe(this.element)
  }

  disconnect() {
    this.observer.disconnect()
    this.visibilityObserver.disconnect()
  }

  scrollToBottom() {
    if (this.bodyValue) {
      window.scrollTo(0, document.body.scrollHeight)
    } else {
      this.element.scrollTop = this.element.scrollHeight - this.element.clientHeight
    }
  }
}
