import { BaseUploadDelegator } from '~js/helpers/file_upload/delegators/base_delegator'

export const createAttachmentDelegator = (context) => {
  return new BaseUploadDelegator(context, {
    add: function (event) {
      const template = document.querySelector('#file-upload')
      this.uploadHandler = template.content.cloneNode(true)
      this.uploadName = this.uploadHandler.querySelector('.name')
      this.uploadName.hidden = true
      this.uploadName.innerText = event.name
      this.uploadProgress = this.uploadHandler.querySelector('.progress')
      this.uploadStatus = this.uploadHandler.querySelector('.status')
      this.uploadRemove = this.uploadHandler.querySelector('.remove')
      this.list.appendChild(this.uploadHandler)
      this.listItem = this.list.lastChild
    },
    progress: function (event) {
      const percentage = parseInt((event.loaded / event.total) * 100)
      this.uploadProgress.setAttribute('value', percentage)
    },
    success: function (blob) {
      this.uploadStatus.removeAttribute('hidden')
      this.uploadRemove.removeAttribute('hidden')
      this.uploadProgress.setAttribute('hidden', true)
      this.uploadName.removeAttribute('hidden')

      const hiddenField = document.createElement('input')
      hiddenField.setAttribute('type', 'hidden')
      hiddenField.setAttribute('value', blob.signed_id)
      hiddenField.name = this.input.name
      this.uploadStatus.parentNode.appendChild(hiddenField)
    },
    error: function () {
      this.uploadHandler.remove()
    }
  })
}
